<template>
<div class="wrapper">
  <el-carousel :interval="5000" class="carousel" height="100%" indicator-position="none" arrow="never">
    <el-carousel-item v-for="item in 3" :key="item" style="width:100%; height:100%">
      <div class="imgBg">
        <img :src="require('../../../assets/images/login/login_bg'+item+'.png')" />
      </div>
    </el-carousel-item>
  </el-carousel>
  <div class="carousel-masking"></div>
  <div class="login">
    <h2>欢迎登录</h2>
    <p>—— 社区综合管理平台</p>
    <el-form ref="loginForm" :model="loginForm" :rules="rules" v-loading="submiting" @keypress.enter.native="submitEvent('loginForm')">
      <el-form-item prop="account">
        <el-input v-model="loginForm.account" placeholder="请输入用户账户"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-row>
          <el-col :span="13">
            <el-input maxlength="4" v-model="loginForm.code" placeholder="请输入验证码" @input="setCode"></el-input>
          </el-col>
          <el-col :span="10" :offset="1">
            <div class="code" @click="getVerifyCode">
              <img :src="loginForm.verifyCodeDomain.base64Img" />
            </div>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- <div style="height: 56px; font-size: 13px;text-align:center">
        <span style="vertical-align: middle;">还没有账号? </span>
        <el-link type="primary">
          <router-link to="/register">申请试用</router-link>
        </el-link>
      </div> -->
      <el-form-item>
        <el-button type="primary" round @click="submitEvent('loginForm')" :disabled="submiting">{{ buttonText }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      submiting: false,
      buttonText: "登录",
      loginForm: {
        account: "",
        password: "",
        code: null,
        verifyCodeDomain: {
          base64Img: "",
          input: "",
          phones: "",
          token: "",
          verifyType: "kaptcha",
        },
      },
      rules: {
        account: [{
          required: true,
          message: "请输入用户账户",
          trigger: "blur",
        }, ],
        password: [{
          required: true,
          message: "请输入用户密码",
          trigger: "blur",
        }, ],
        code: [{
          required: true,
          message: "请输入图形验证码",
          trigger: "blur",
        }, ],
      },
    };
  },
  created() {
    localStorage.clear();
    this.getVerifyCode();
  },
  computed: {
    ...mapState(["isCollapse", "wellCome", "desktop"]),
  },
  methods: {
    ...mapMutations(["goToPage"]),
    ...mapActions(["getVerifyCodeService", "LoginServe"]),
    // 获取图形验证码
    getVerifyCode() {
      this.loginForm.code = null;
      this.loginForm.verifyCodeDomain.input = "";
      this.getVerifyCodeService({
          params: {
            verifyType: this.loginForm.verifyCodeDomain.verifyType,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            let data = res.data;
            this.loginForm.verifyCodeDomain = data;
          }
        })
        .catch((err) => {});
    },

    setCode() {
      this.loginForm.verifyCodeDomain.input = this.loginForm.code;
    },

    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          localStorage.clear();
          this.buttonText = "登录中...";
          this.submiting = true;
          this.LoginServe({
            params: this.loginForm,
          }).then(({ code, data }) => {
            if (code == 200) {
              this.$message.success("登录成功");
              //默认初始化一个菜单
              let { loginToken, loginUser, sysAppResources } = data;
              if (!sysAppResources.length || !sysAppResources[0].children.length) {
                sysAppResources = [{
                  id: 0,
                  code: "Home",
                  name: "首页",
                  fullName: "首页",
                  icon: "el-icon-trophy",
                  index: "1",
                  children: [this.wellCome],
                }];
              }
              localStorage.token = loginToken;
              localStorage.userInfo = JSON.stringify(loginUser);
              localStorage.resources = JSON.stringify(sysAppResources);
              this.goToPage(sysAppResources[0].children[0]);
            } else {
              this.getVerifyCode();
            }
            this.formReset();
          }).catch(err => {
            this.formReset();
          });
        } else {
          this.$message.error("请完善登录信息");
          return false;
        }
      });
    },

    // 重置表单
    formReset() {
      this.loginForm.verifyCodeDomain.input = null;
      this.submiting = false;
      this.buttonText = "登录";
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  min-height: 600px;
  // background: url("../../../assets/images/login/login_bg.png") no-repeat top center / cover;
  background-color: #fff;
  position: relative;

  .carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .carousel-masking {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  .imgBg {
    height: 100%;
    box-sizing: border-box;
    text-align: right;

    img {
      height: 100%;
    }
  }

  .login {
    width: 360px;
    height: 460px;
    padding: 0px;
    box-sizing: border-box;
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 10%;
    margin-top: -230px;
    z-index: 9;

    ::v-deep .el-input__inner {
      border-radius: 20px !important;
      background-color: #f3f1f7;
    }

    p {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 30px;
      color: #666;
    }

    h2 {
      height: 40px;
      line-height: 40px;
      color: #333333;
    }

    .code {
      height: 40px;
      overflow: hidden;
      cursor: pointer;

      img {
        height: 40px;
      }
    }

    .el-button {
      width: 100%;
    }
  }
}
</style>
